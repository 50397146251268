@media all and (min-width: 480px) {
  .contentTitle {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .link {
    font-size: 18px;
    text-align: center;
    margin-top: 15px;
  }
  .line {
    height: 1;
    color: #979797;
    background-color: #979797;
  }
}
