@media all and (min-width: 480px) {
  .card-body {
    padding: 0px;
  }
  .list-none {
    color: gray;
    font-style: italic;
  }
  .fa-1x {
    opacity: 1;
  }
  .dropdown-menu > li > a:hover {
    background-image: none;
    background-color: red;
  }
}
