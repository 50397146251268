@media all {
  .nav-link {
    color: white;
  }
  .nav-link:hover {
    text-decoration: underline;
  }
  .active {
    font-weight: bold;
    color: white;
  }
  .active:hover {
    font-weight: bold;
    color: white;
    text-decoration: none;
  }
}
